<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" @click="handleCreate"><i class="ni ni-fat-add"></i> Thêm người dùng</base-button>
          <base-button size="sm" type="neutral"><i class="ni ni ni-ui-04"></i> Bộ lọc</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Danh sách người dùng</h3>
          </template>

          <div>
            <el-table v-loading="loading" :data="list" header-row-class-name="thead-light">
              <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.index }}</span>
                </template>
              </el-table-column>

              <el-table-column align="left" label="Tên">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>

              <el-table-column align="left" label="Email">
                <template slot-scope="scope">
                  <span>{{ scope.row.email }}</span>
                </template>
              </el-table-column>

              <el-table-column align="left" label="Quyền" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.roles | formatRole(scope.row.roles) }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center" label="Actions" width="350">
                <template slot-scope="scope">
                  <base-button
                    v-if="!scope.row.name !== 'admin'"
                    v-permission="['manage_user']"
                    @click="handleEditUser(scope.row)"
                    class="view btn-link"
                    type="default"
                    size="sm"
                    icon
                  >
                    <font-awesome-icon icon="user-edit" inverse />
                  </base-button>


                  <base-button
                    v-if="!scope.row.roles.includes('admin')"
                    v-permission="['manage_user']"
                    @click="handleEditPermissions(scope.row.id)"
                    class="view btn-icon"
                    type="primary"
                    size="sm"
                    icon
                  >
                  <font-awesome-icon icon="edit" inverse /> Quyền
                  </base-button>

                  <el-button v-if="scope.row.roles.includes('visitor')" v-permission="['manage_user']" type="danger" size="small" icon="el-icon-delete" @click="handleDelete(scope.row.id, scope.row.name);">
                    Xoá
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog :visible.sync="dialogPermissionVisible" :title="'Chỉnh sửa quyền - ' + currentUser.name">
              <div v-if="currentUser.name" v-loading="dialogPermissionLoading" class="form-container">
                <div class="permissions-container">
                  <div class="block">
                    <el-form :model="currentUser" label-width="80px" label-position="top">
                      <el-form-item label="Truy cập menu">
                        <el-tree ref="menuPermissions" :data="normalizedMenuPermissions" :default-checked-keys="permissionKeys(userMenuPermissions)" :props="permissionProps" show-checkbox node-key="id" class="permission-tree" />
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="block">
                    <el-form :model="currentUser" label-width="80px" label-position="top">
                      <el-form-item label="Quyền">
                        <el-tree ref="otherPermissions" :data="normalizedOtherPermissions" :default-checked-keys="permissionKeys(userOtherPermissions)" :props="permissionProps" show-checkbox node-key="id" class="permission-tree" />
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="clear-left" />
                </div>
                <div style="text-align:right;">
                  <base-button
                    @click="dialogPermissionVisible=false"
                    class="view"
                    type="danger"
                    size="md"
                    icon
                  >
                    Huỷ
                  </base-button>

                  <base-button
                    @click="confirmPermission"
                    class="view"
                    type="primary"
                    size="md"
                    icon
                  >
                    Xác nhận
                  </base-button>
                </div>
              </div>
            </el-dialog>

            <el-dialog :title="isUserEditing ? 'Chỉnh sửa tài khoản' : 'Tạo tài khoản mới'" :visible.sync="dialogFormVisible">
              <div v-loading="userCreating" class="form-container">
                <el-form ref="userForm" :rules="isUserEditing ? editRules : rules" :model="newUser" label-position="left" label-width="150px" style="max-width: 500px;">
                  <el-form-item label="Quyền" prop="role">
                    <base-input>
                      <select v-model="newUser.role" class="form-control">
                        <option :label="'Admin'" :value="'admin'" v-role="['admin']"></option>
                        <option v-for="(item, key) in roleOptions" :key="key" :label="item" :value="key"></option>
                      </select>
                    </base-input>
                  </el-form-item>
                  <el-form-item label="Tên" prop="name">
                    <el-input v-model="newUser.name" />
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="newUser.email" />
                    <div v-if="errors.get('email')" class="invalid-feedback" :style="{display: errors.get('email') ? 'block' : 'none'}"> {{ errors.get('email') }} </div>
                  </el-form-item>
                  <el-form-item label="Mật khẩu" prop="password">
                    <el-input v-model="newUser.password" show-password />
                    <div v-if="errors.get('password')" class="invalid-feedback" :style="{display: errors.get('password') ? 'block' : 'none'}"> {{ errors.get('password') }} </div>
                  </el-form-item>
                  <el-form-item label="Xác nhận mật khẩu" prop="confirmPassword">
                    <el-input v-model="newUser.confirmPassword" show-password />
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false">
                    Huỷ
                  </el-button>
                  <el-button v-if="!isUserEditing" type="primary" @click="createUser()">
                    Xác nhận
                  </el-button>

                  <el-button v-else type="primary" @click="updateUser()">
                    Cập nhật
                  </el-button>
                </div>
              </div>
            </el-dialog>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Tổng: {{ total }} kết quả.
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Dialog, Button, Form, FormItem, Tree } from 'element-ui';
import permission from '@/directives/permission'; // Permission directive (v-permission)
import role from '@/directives/role'; // Role directive (v-role)
import { BasePagination } from '@/components';
import checkPermission from '@/utils/permission'; // Permission checking
import { labels } from '@/utils/labels';
import _ from 'lodash';
import Errors from '@/utils/error';

const roleNames = {
  admin: 'Admin',
  manager: 'Quản lý',
  member: 'Nhân viên',
  guest: 'Khách',
};

export default {
  name: "Users",
  directives: { permission, role },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Tree.name]: Tree,
  },
  filters: {
    formatRole(roles) {
      const roleToNames = roles.map(role => roleNames[role]);
      return roleToNames.join(',');
    },
  },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.newUser.password) {
        callback(new Error('Xác nhận mật khẩu không đúng!'));
      } else {
        callback();
      }
    };
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      userCreating: false,
      isUserEditing: false,
      query: {
        page: 1,
        limit: 15,
        keyword: '',
        role: '',
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      roles: ['admin', 'manager', 'user', 'guest'],
      nonAdminRoles: {
        member: 'Nhân viên',
        guest: 'Khách',
      },
      roleOptions: {
        manager: 'Quản lý',
        member: 'Nhân viên',
        guest: 'Khách',
      },
      newUser: {},
      dialogFormVisible: false,
      dialogPermissionVisible: false,
      dialogPermissionLoading: false,
      currentUserId: 0,
      currentUser: {
        name: '',
        permissions: [],
        rolePermissions: [],
      },
      rules: {
        role: [{ required: true, message: 'Trường này không được trống', trigger: 'change' }],
        name: [{ required: true, message: 'Trường này không được trống', trigger: 'blur' }],
        email: [
          { required: true, message: 'Trường này không được trống', trigger: 'blur' },
          { type: 'email', message: 'Định dạng email không đúng', trigger: ['blur', 'change'] },
        ],
        password: [{ required: true, message: 'Trường này không được trống', trigger: 'blur' }],
        confirmPassword: [{ validator: validateConfirmPassword, trigger: 'blur' }],
      },
      editRules: {
        role: [{ required: true, message: 'Trường này không được trống', trigger: 'change' }],
        name: [{ required: true, message: 'Trường này không được trống', trigger: 'blur' }],
        email: [
          { required: true, message: 'Trường này không được trống', trigger: 'blur' },
          { type: 'email', message: 'Định dạng email không đúng', trigger: ['blur', 'change'] },
        ],
        confirmPassword: [{ validator: validateConfirmPassword, trigger: 'blur' }],
      },
      errors: new Errors(),
      permissionProps: {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      },
      permissions: [],
      menuPermissions: [],
      otherPermissions: [],
    };
  },
  computed: {
    normalizedMenuPermissions() {
      let tmp = [];
      this.currentUser.permissions.role.forEach(permission => {
        tmp.push({
          id: permission.id,
          name: permission.name,
          disabled: true,
        });
      });
      const rolePermissions = {
        id: -1, // Just a faked ID
        name: 'Inherited from role',
        label: 'Inherited from role',
        disabled: true,
        children: this.classifyPermissions(tmp).menu,
      };

      tmp = this.menuPermissions.filter(permission => !this.currentUser.permissions.role.find(p => p.id === permission.id));
      const userPermissions = {
        id: 0, // Faked ID
        name: 'Extra menus',
        label: 'Extra menus',
        children: tmp,
        disabled: tmp.length === 0,
      };

      return [rolePermissions, userPermissions];
    },
    normalizedOtherPermissions() {
      let tmp = [];
      this.currentUser.permissions.role.forEach(permission => {
        tmp.push({
          id: permission.id,
          name: permission.name,
          disabled: true,
        });
      });
      const rolePermissions = {
        id: -1,
        name: 'Inherited from role',
        label: 'Inherited from role',
        disabled: true,
        children: this.classifyPermissions(tmp).other,
      };

      tmp = this.otherPermissions.filter(permission => !this.currentUser.permissions.role.find(p => p.id === permission.id));
      const userPermissions = {
        id: 0,
        name: 'Extra permissions',
        label: 'Extra permissions',
        children: tmp,
        disabled: tmp.length === 0,
      };

      return [rolePermissions, userPermissions];
    },
    userMenuPermissions() {
      return this.classifyPermissions(this.userPermissions).menu;
    },
    userOtherPermissions() {
      return this.classifyPermissions(this.userPermissions).other;
    },
    userPermissions() {
      return this.currentUser.permissions.role.concat(this.currentUser.permissions.user);
    },
  },
  created() {
    this.resetNewUser();
    this.getList();
    if (checkPermission(['manage_permission'])) {
      this.getPermissions();
    }
  },
  methods: {
    checkPermission,
    async getRoles() {
      this.loading = true;
      const { data } = await this.$store.dispatch('permission/fetchRoles');
      this.list = data;
      this.list.forEach((role, index) => {
        role['index'] = index + 1;
        role['description'] = _.at(labels, 'permission.' + 'roles.description.' + role.name)[0];
      });
      this.loading = false;
    },

    async getPermissions() {
      const { data } = await this.$store.dispatch('permission/fetchPermissions');
      const { all, menu, other } = this.classifyPermissions(data);
      this.permissions = all;
      this.menuPermissions = menu;
      this.otherPermissions = other;
    },

    classifyPermissions(permissions) {
      const all = []; const menu = []; const other = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        all.push(permission);
        if (permissionName.startsWith('view_menu')) {
          menu.push(this.normalizeMenuPermission(permission));
        } else {
          other.push(this.normalizePermission(permission));
        }
      });
      return { all, menu, other };
    },

    normalizeMenuPermission(permission) {
      return {
        id: permission.id,
        name: permission.name.substring(10).toLowerCase(),
        label: _.at(labels,'permission.permissions.menu.' + permission.name.substring(10).toLowerCase())[0],
      };
    },

    normalizePermission(permission) {
      return {
        id: permission.id,
        name: permission.name.toLowerCase(),
        label: _.at(labels, 'permission.permissions.can.' + permission.name.toLowerCase())[0],
        disabled: permission.name === 'manage_permission',
      };
    },

    permissionKeys(permissions) {
      return permissions.map(permssion => permssion.id);
    },

    async getList() {
      const { limit, page } = this.query;
      this.loading = true;
      const { data, meta } = await this.$store.dispatch('user/fetchUsers');
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    handleCreate() {
      this.resetNewUser();
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate();
      });
    },
    handleDelete(id, name) {
      this.$confirm('This will permanently delete user ' + name + '. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        userResource.destroy(id).then(response => {
          this.$message({
            type: 'success',
            message: 'Delete completed',
          });
          this.handleFilter();
        }).catch(error => {
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },

    handleEditUser(user) {
      this.dialogFormVisible = true;
      this.isUserEditing = true;
      this.newUser = user;
      this.newUser.role = user.roles[0];
    },

    updateUser() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.newUser.roles = [this.newUser.role];
          this.userCreating = true;
          this.$store.dispatch('user/updateUser', this.newUser)
            .then(response => {
              this.$notify({
                message:
                  !response.error ? 'Cập nhật người dùng thành công!' : 'Cập nhật người dùng không thành công!',
                timeout: 2000,
                icon: 'ni ni-bell-55',
                type: !response.error ? 'success' : 'danger',
                verticalAlign: 'bottom',
                horizontalAlign: 'right'
              });
              this.resetNewUser();
              this.dialogFormVisible = false;
              this.handleFilter();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    createUser() {
      let self = this;
      this.errors = new Errors();

      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.newUser.roles = [this.newUser.role];
          this.userCreating = true;
          this.$store.dispatch('user/addUser', this.newUser)
            .then(response => {
              this.$notify({
                message:
                  !response.error ? 'Tạo người dùng mới thành công!' : 'Tạo người dùng mới không thành công!',
                timeout: 2000,
                icon: 'ni ni-bell-55',
                type: !response.error ? 'success' : 'danger',
                verticalAlign: 'bottom',
                horizontalAlign: 'right'
              });
              this.resetNewUser();
              this.dialogFormVisible = false;
              this.handleFilter();
            })
            .catch(error => {
              self.errors.record(error.response.data);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    resetNewUser() {
      this.newUser = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'guest',
      };
    },

    // handleDownload() {
    //   this.downloading = true;
    //   import('@/vendor/Export2Excel').then(excel => {
    //     const tHeader = ['id', 'user_id', 'name', 'email', 'role'];
    //     const filterVal = ['index', 'id', 'name', 'email', 'role'];
    //     const data = this.formatJson(filterVal, this.list);
    //     excel.export_json_to_excel({
    //       header: tHeader,
    //       data,
    //       filename: 'user-list',
    //     });
    //     this.downloading = false;
    //   });
    // },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    async handleEditPermissions(id) {
      this.currentUserId = id;
      this.dialogPermissionLoading = true;
      this.dialogPermissionVisible = true;
      const found = this.list.find(user => user.id === id);
      const { data } = await this.$store.dispatch('user/fetchUserPermissions', {id: id});
      this.currentUser = {
        id: found.id,
        name: found.name,
        permissions: data,
      };
      this.dialogPermissionLoading = false;
      this.$nextTick(() => {
        this.$refs.menuPermissions.setCheckedKeys(this.permissionKeys(this.userMenuPermissions));
        this.$refs.otherPermissions.setCheckedKeys(this.permissionKeys(this.userOtherPermissions));
      });
    },

    confirmPermission() {
      const checkedMenu = this.$refs.menuPermissions.getCheckedKeys();
      const checkedOther = this.$refs.otherPermissions.getCheckedKeys();
      const checkedPermissions = checkedMenu.concat(checkedOther);
      this.dialogLoading = true;

      this.$store.dispatch('permission/updateRole', {id: this.currentRole.id, permissions: checkedPermissions }).then(response => {
        this.$notify({
          message:
            !response.error ? 'Chỉnh sửa quyền thành công!' : 'Chỉnh sửa quyền không thành công!',
          timeout: 2000,
          icon: 'ni ni-bell-55',
          type: !response.error ? 'success' : 'danger',
          verticalAlign: 'bottom',
          horizontalAlign: 'right'
        });
        this.dialogLoading = false;
        this.dialogVisible = false;
        this.getRoles();
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.permissions-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
