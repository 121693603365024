export const labels = {
  'permission': {
    'roles': {
      'description': {
        'admin': 'Quyền admin',
        'manager': 'Quyền quản trị viên',
        'member': 'Quyền nhân viên',
        'guest': 'Quyền khách',
      }
    },
    'permissions': {
      'menu': {
        'permission': 'Menu phân quyền',
        'administrator': 'Menu Admin',
        'link_api': 'Menu quản lý submitlink api',
      },
      'can': {
        'manage_user': 'Quản lý người dùng',
        'manage_permission': 'Quản lý phân quyền',
        'manage_link_api': 'Quản lý submitlink api',
        'manage_project': 'Thêm, chỉnh sửa dự án',
        'manage_submit_link': 'Thêm, chỉnh sửa submit link',
      }
    }
  }
};
